<template>
  <div>
    <section class="search-form-section">
      <form
        method="POST"
        class="search-form"
        id="comparerSearchForm"
        action="/collations/client"
      >
        <div class="search-form__item">
          <label>Kwota kredytu</label>
          <div class="input-suffix input-suffix--pln">
            <input
              name="credit-amount"
              type="text"
              class="input-suffix input-suffix--pln amount"
              greatherThan="5000"
              v-model="params.amount"
              @keyup="amountChanged"
              @keydown="keyDown"
            />
          </div>
        </div>
        <div class="search-form__item">
          <label class="d-flex align-items-center"
            >Okres kredytowania
            <span
              class="question-mark pointerable"
              data-html="true"
              data-tippy-content="Banki oferują zazwyczaj kredyty hipoteczne na okres 5-35 lat."
            >
              <svg
                width="7"
                height="11"
                viewBox="0 0 7 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.57 0.116C4.494 0.116 5.24067 0.377333 5.81 0.899999C6.37933 1.42267 6.664 2.12267 6.664 3C6.664 3.98 6.36067 4.708 5.754 5.184C5.14733 5.65067 4.326 5.884 3.29 5.884L3.248 7.214H2.142L2.086 5.002H2.492C3.416 5.002 4.13933 4.862 4.662 4.582C5.18467 4.302 5.446 3.77467 5.446 3C5.446 2.44 5.278 1.99667 4.942 1.67C4.606 1.34333 4.15333 1.18 3.584 1.18C3.00533 1.18 2.548 1.33867 2.212 1.656C1.88533 1.964 1.722 2.38867 1.722 2.93H0.518C0.518 2.37 0.644 1.88 0.896 1.46C1.148 1.03067 1.50267 0.699333 1.96 0.465999C2.42667 0.232666 2.96333 0.116 3.57 0.116ZM2.688 10.084C2.44533 10.084 2.24 10 2.072 9.832C1.904 9.664 1.82 9.45867 1.82 9.216C1.82 8.97333 1.904 8.768 2.072 8.6C2.24 8.432 2.44533 8.348 2.688 8.348C2.92133 8.348 3.11733 8.432 3.276 8.6C3.444 8.768 3.528 8.97333 3.528 9.216C3.528 9.45867 3.444 9.664 3.276 9.832C3.11733 10 2.92133 10.084 2.688 10.084Z"
                  fill="#332930"
                  fill-opacity="0.7"
                />
              </svg>
            </span>
          </label>
          <div class="input-suffix input-suffix--age">
            <input
              name="payment-period"
              type="text"
              v-model="params.period"
              @keydown="keyDown"
              min="5"
              max="35"
            />
          </div>
        </div>
        <div class="search-form__item">
          <label>Cena nieruchomości</label>
          <div class="input-suffix input-suffix--pln">
            <input
              name="estate-price"
              type="text"
              v-model="params.property_value"
              greatherThan="5500"
              class="amount"
              @keyup="amountChanged"
              @keydown="keyDown"
            />
          </div>
        </div>
        <div class="search-form__item">
          <label>Wkład własny</label>
          <div class="input-suffix input-suffix--percent">
            <input
              name="own-contribution"
              type="text"
              class="amount"
              v-model="params.own_contribution"
              lessThan="100"
              greatherThan="0"
              @keyup="contributionChanged"
              @keydown="keyDown"
            />
          </div>
        </div>
        <div class="search-form__item search-form__item--interest">
          <label class="d-flex align-items-center"
            >Oprocentowanie
            <span
              class="question-mark pointerable"
              data-html="true"
              data-tippy-content="<strong>Oprocentowanie zmienne</strong> składa się z marży banku oraz WIBOR (stawka referencyjna). Takie oprocentowanie zmienia się co <strong>3 lub 6 miesięcy</strong> w zależności od banku. W związku z tym wysokość Twoich rat również będzie mogła ulegać zmianom. <br/><br/><strong>Oprocentowanie stałe</strong> jest ustalane na jednakowym poziomie na okres <strong>minimum 5 lat</strong>. Oznacza to, że <strong>przez ten okres będziesz płacił stałą ratę niezależnie od bieżących wahań stóp procentowych</strong>."
            >
              <svg
                width="7"
                height="11"
                viewBox="0 0 7 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.57 0.116C4.494 0.116 5.24067 0.377333 5.81 0.899999C6.37933 1.42267 6.664 2.12267 6.664 3C6.664 3.98 6.36067 4.708 5.754 5.184C5.14733 5.65067 4.326 5.884 3.29 5.884L3.248 7.214H2.142L2.086 5.002H2.492C3.416 5.002 4.13933 4.862 4.662 4.582C5.18467 4.302 5.446 3.77467 5.446 3C5.446 2.44 5.278 1.99667 4.942 1.67C4.606 1.34333 4.15333 1.18 3.584 1.18C3.00533 1.18 2.548 1.33867 2.212 1.656C1.88533 1.964 1.722 2.38867 1.722 2.93H0.518C0.518 2.37 0.644 1.88 0.896 1.46C1.148 1.03067 1.50267 0.699333 1.96 0.465999C2.42667 0.232666 2.96333 0.116 3.57 0.116ZM2.688 10.084C2.44533 10.084 2.24 10 2.072 9.832C1.904 9.664 1.82 9.45867 1.82 9.216C1.82 8.97333 1.904 8.768 2.072 8.6C2.24 8.432 2.44533 8.348 2.688 8.348C2.92133 8.348 3.11733 8.432 3.276 8.6C3.444 8.768 3.528 8.97333 3.528 9.216C3.528 9.45867 3.444 9.664 3.276 9.832C3.11733 10 2.92133 10.084 2.688 10.084Z"
                  fill="#332930"
                  fill-opacity="0.7"
                />
              </svg>
            </span>
          </label>
          <div class="search-form__interest">
            <div class="input-checkbox-wrapper">
              <input
                id="changing-interest"
                class="pointerable"
                name="interest"
                type="radio"
                value="1"
                v-model="params.type_interest"
              />
              <label for="changing-interest" class="pl-4 pointerable"
                >Zmienne</label
              >
            </div>
            <div class="input-checkbox-wrapper">
              <input
                id="constant-interest"
                class="pointerable"
                name="interest"
                type="radio"
                value="2"
                v-model="params.type_interest"
              />
              <label for="constant-interest" class="pl-4 pointerable"
                >Stałe</label
              >
            </div>
          </div>
        </div>
        <div class="search-form__summary">
          <div class="d-flex justify-content-center align-items-center">
            <p
              class="search-form__other-criteria-text text-center mobile-hide"
              v-if="!params.noform"
            >
              + pozostałe kryteria: {{ criteria }} (<a
                class="pointerable search-form__other-criteria-change"
                @click="$emit('showForm')"
                >ZMIEŃ</a
              >)
            </p>
          </div>
          <div
            class="d-flex justify-content-end search-form__other-criteria-button"
          >
            <button
              type="submit"
              class="button button--rounded mobile-hide"
              :disabled="searchDisabled || loading"
            >
              <img
                v-if="loading"
                src="../../../public/assets/img/three-dots.svg"
                width="40"
                alt=""
              />
              <span v-else>Szukaj</span>
            </button>
          </div>
          <template v-if="!params.noform">
            <label class="w-100 desktop-hide mt-6">Pozostałe kryteria</label>
            <div class="search-form__others flex-wrap mb-4 desktop-hide">
              <p class="search-form__other-criteria-text">
                {{ criteria }}
                <a
                  class="pointerable search-form__other-criteria-change"
                  @click="$emit('showForm')"
                  >(ZMIEŃ)</a
                >
              </p>
            </div>
          </template>
          <!--          <div class="search-form__others mt-5 mb-4 pb-3 pt-3 desktop-hide">-->
          <!--            <input type="checkbox">-->
          <!--            <p class="search-form__other-criteria-text ml-4">Pokaż tylko 1 najlepszą ofertę z każdego banku</p>-->
          <!--          </div>-->

          <div class="d-flex justify-content-center">
            <button type="submit" class="button button--rounded desktop-hide">
              <img
                v-if="loading"
                src="../../../public/assets/img/three-dots.svg"
                width="40"
                alt=""
              />

              <span v-else>Szukaj</span>
            </button>
            <!-- <input
              type="submit"
              value="Szukaj"
            /> -->
          </div>
        </div>
        <div>
          <div>
            <div class="form-element mt-8">
              <div class="d-flex">
                <input
                  id="regulations"
                  class="pointerable"
                  name="regulations"
                  :required="true"
                  type="checkbox"
                  v-model="regulations"
                />
                <label for="regulations" class="pl-4 pointerable intro__legal">
                  Zapoznałam/em się z
                  <a
                    target="_blank"
                    href="https://drive.google.com/file/d/1FFn49MG6qY5PJNAlNds8zm0dRq-B28a5/view"
                    >Regulaminem</a
                  >
                  porównywarki kredytów hipotecznych i akceptuję jego treść.
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>
<style>
#regulations-error {
  position: relative;
  bottom: 0px;
}
</style>

<script>
import ajax from "@/services/ajax";
import helper from "@/services/helper";
import auth from "@/services/auth";
import $ from "jquery";
import tippy from "tippy.js";
import Inputmask from "inputmask";

export default {
  name: "searchForm",
  data() {
    return {
      params: {},
      searchDisabled: false,
    };
  },
  computed: {
    criteria() {
      if (this.params.noform) {
        return "";
      }
      let string = "";
      switch (parseInt(this.parameters.property_type)) {
        case 1:
          string += "mieszkanie, ";
          break;
        case 2:
          string += "dom, ";
          break;
        case 3:
          string += "lokal użytkowy, ";
          break;
        case 4:
          string += "działka budowlana, ";
          break;
      }

      switch (parseInt(this.parameters.developer)) {
        case 1:
          string += "od dewelopera, ";
          break;
        case 0:
          string += "rynek wtórny, ";
          break;
      }
      string +=
        "wiek najstarszego kredytobiorcy: " + this.parameters.age + " lat";

      return string;
    },
  },
  props: ["parameters", "loading"],
  watch: {
    "params.own_contribution"() {
      if (
        helper.parseAmount(this.params.amount) >
        helper.parseAmount(this.params.property_value)
      ) {
        this.params.own_contribution = 0;
        this.searchDisabled = true;
      } else {
        this.searchDisabled = false;
      }
    },
    helper(val) {
      return helper.parseAmount(val);
    },
    parameters() {
      if (this.parameters) {
        this.params = this.parameters;
        this.amountChanged();
      } else {
        this.params = {};
      }
    },
  },
  methods: {
    keyDown: function (e) {
      if (
        [
          8, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 65, 96, 97, 98, 99, 100,
          101, 102, 103, 104, 105, 188, 110, 190, 37, 39, 109, 9,
        ].indexOf(e.which) === -1
      ) {
        e.preventDefault();
      }
    },
    contributionChanged() {
      let result =
        (helper.parseAmount(this.params.property_value) *
          (100 - helper.parseAmount(this.params.own_contribution))) /
        100;
      if ($.isNumeric(result)) {
        this.params.amount = result;
      } else {
        this.params.amount = 0;
      }
    },
    amountChanged() {
      this.params.amount = helper.parseAmount(this.params.amount);
      let result =
        (100 *
          (helper.parseAmount(this.params.property_value) -
            helper.parseAmount(this.params.amount))) /
        helper.parseAmount(this.params.property_value);
      if (
        helper.parseAmount(this.params.amount) >
        helper.parseAmount(this.params.property_value)
      ) {
        this.params.own_contribution = 0;
        this.searchDisabled = true;
      } else {
        this.searchDisabled = false;
      }
      if ($.isNumeric(result)) {
        this.params.own_contribution = result;
      } else {
        this.params.own_contribution = 0;
      }
    },
  },
  mounted() {
    let self = this;
    auth.check();
    if (this.parameters) {
      this.params = this.parameters;
      this.amountChanged();
    }
    tippy("[data-tippy-content]", { allowHTML: true });

    Inputmask({
      alias: "numeric",
      allowMinus: false,
      groupSeparator: " ",
      autoGroup: true,
      digits: 2,
      max: 999999999.99,
    }).mask(".amount");
    ajax.justValidate($("#comparerSearchForm"), () => {
      self.$emit("set", self.params);
    });
  },
};
</script>
