<template>
  <div class="mb-5">
    <div v-if="loading">
      <div class="d-flex justify-content-center mt-7">
        <spinner style="display: none" />
        <div class="w-100">
          <div
            v-for="index in 12"
            :key="index"
            class="offer-item offer-item-loading mb-5"
          >
            <div class="row">
              <div class="col-12 col-lg-2">
                <div class="offer-item__left-col pt-4">
                  <img
                    src="../../../public/assets/img/three-dots-slate.svg"
                    width="40"
                    alt=""
                  />
                  <!-- <div class="offer-item__bank-logo-grey"></div>
                  <div class="badge--loading desktop-hide"></div> -->
                </div>
              </div>
              <div class="col-12 col-lg-8">
                <div class="row w-100 offer-item__row">
                  <div class="col-6 col-md-3 offer-item__col-first">
                    <div
                      class="d-flex flex-column offer-item__result align-items-center"
                    >
                      <div
                        class="d-flex flex-column offer-item__result-wrapper justify-content-end rrso"
                      >
                        <div class="offer-item__result-details-grey mb-3"></div>
                        <div class="offer-item__result-details-grey"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-3 offer-item__col-second">
                    <div
                      class="d-flex flex-column offer-item__result justify-content-end align-items-center"
                    >
                      <div
                        class="d-flex flex-column offer-item__result-wrapper justify-content-end"
                      >
                        <div class="offer-item__result-details-grey mb-3"></div>
                        <div class="offer-item__result-details-grey"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-6 col-md-3 col-xl-2 offset-xl-1 offer-item__col-third"
                  >
                    <div
                      class="d-flex flex-column offer-item__result justify-content-end align-items-center"
                    >
                      <div
                        class="d-flex flex-column offer-item__result-wrapper justify-content-end"
                      >
                        <div class="offer-item__result-details-grey mb-3"></div>
                        <div class="offer-item__result-details-grey"></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-3 offer-item__col-fourth">
                    <div
                      class="d-flex flex-column offer-item__result align-items-center"
                    >
                      <div
                        class="d-flex flex-column offer-item__result-wrapper justify-content-end"
                      >
                        <div class="offer-item__result-details-grey mb-3"></div>
                        <div class="offer-item__result-details-grey"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-2">
                <div class="offer-item__right-col">
                  <div class="d-flex offer-item__right-col-content">
                    <div class="badge--loading mb-3 mobile-hide"></div>
                    <div class="d-flex align-items-center">
                      <div
                        class="offer-item__result-details-loading offer-item__result-details-loading-expand"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!fetched" class="text-center" style="min-height: 50vh">
      <h2 class="header__title">Nie rozpoczęto wyszukiwania</h2>
      <p>Wypełnij powyszy formularz i wyszukaj oferty</p>
      <div class="d-flex justify-content-center mt-8">
        <a
          href="#"
          class="button button--rounded"
          data-bs-toggle="modal"
          data-bs-target="#leadForm"
          @click="scrollUp"
          >umów bezpłatną konsultację z ekspertem</a
        >
      </div>
    </div>
    <div v-else-if="offers && offers.length > 0">
      <div
        v-for="(offer, key) in fixedDataOffers"
        :key="'offer_' + key"
        class="offer-item mb-5"
        :class="{ 'offer-item--expanded': isOpen === offer.uid }"
      >
        <div class="row">
          <div class="col-12 col-lg-2">
            <div class="offer-item__left-col">
              <div class="offer-item__bank-logo">
                <img
                  :src="
                    '../../../assets/img/banks/' +
                    getLogo(offer.institution_name)
                  "
                  alt="bank logo"
                />
              </div>
              <button
                class="badge badge--red desktop-hide"
                data-bs-toggle="modal"
                data-bs-target="#leadForm"
                @click="scrollUp"
              >
                Umów rozmowę
              </button>
              <transition v-on:enter="enter" v-on:leave="leave">
                <div
                  v-show="
                    isOpen === offer.uid &&
                    offer.expires_at &&
                    offer.expires_at.length > 0
                  "
                  class="offer-item__bank-validity mobile-hide"
                >
                  <p>Oferta ważna do {{ offer.expires_at }}!</p>
                </div>
              </transition>
            </div>
          </div>
          <div class="col-12 col-lg-8">
            <div class="row w-100 offer-item__row">
              <div class="col-6 col-md-3 offer-item__col-first">
                <div class="d-flex flex-column offer-item__result">
                  <div
                    class="d-flex flex-column offer-item__result-wrapper justify-content-end rrso"
                  >
                    <label
                      >Rata <span class="mobile-hide">miesięczna</span></label
                    >
                    <p class="offer-item__result-details">
                      <span>{{ $filters.money(offer.installments) }}</span
                      ><span class="ml-4">PLN</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3 offer-item__col-second">
                <div
                  class="d-flex flex-column offer-item__result offer-item__center"
                >
                  <div
                    class="d-flex flex-column offer-item__result-wrapper justify-content-end"
                  >
                    <label>Prowizja banku</label>
                    <p class="offer-item__result-details">
                      <span>{{ $filters.money(offer.commission) }}</span
                      ><span class="ml-4">%</span>
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="col-6 col-md-3 col-xl-2 offset-xl-1 offer-item__col-third"
              >
                <div
                  class="d-flex flex-column offer-item__result offer-item__center"
                >
                  <div
                    class="d-flex flex-column offer-item__result-wrapper justify-content-end"
                  >
                    <label>Oprocent.</label>
                    <p
                      class="offer-item__result-details"
                      v-if="!offer.interest_constant_duration"
                    >
                      <span>{{
                        $filters.money(
                          parseFloat(offer.rate_value) +
                            parseFloat(offer.margin)
                        )
                      }}</span
                      ><span class="ml-4">%</span>
                    </p>
                    <p class="offer-item__result-details" v-else>
                      <span>{{
                        $filters.money(
                          parseFloat(offer.interest_constant_value)
                        )
                      }}</span
                      ><span class="ml-4">%</span>
                    </p>
                  </div>
                  <div v-show="isOpen === offer.uid" class="mobile-hide">
                    <svg
                      v-if="!offer.interest_constant_duration"
                      class="offer-item__result-arrow-first"
                      width="136"
                      height="44"
                      viewBox="0 0 136 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.28897 43.7032C7.67732 44.0959 8.31047 44.0994 8.70316 43.711L15.1024 37.3826C15.4951 36.9942 15.4986 36.3611 15.1103 35.9684C14.7219 35.5757 14.0888 35.5722 13.6961 35.9605L8.00786 41.5858L2.38255 35.8976C1.99421 35.5049 1.36105 35.5014 0.968362 35.8897C0.575672 36.2781 0.572151 36.9112 0.960498 37.3039L7.28897 43.7032ZM133.786 0.800071C130.879 3.76575 126.779 5.93234 121.742 7.51467C116.708 9.09596 110.799 10.0749 104.339 10.7055C91.3851 11.9701 76.4663 11.8187 62.1225 12.4112C47.8632 13.0002 34.1882 14.326 24.0445 18.6189C18.9547 20.7729 14.6982 23.6961 11.7072 27.6979C8.70791 31.7109 7.03481 36.7371 7.00002 42.9944L8.99998 43.0056C9.03271 37.1198 10.5978 32.5231 13.3092 28.8953C16.029 25.2564 19.9559 22.521 24.824 20.4607C34.5963 16.3249 47.9356 14.9989 62.2051 14.4095C76.3901 13.8236 91.5143 13.967 104.534 12.696C111.06 12.0589 117.121 11.0624 122.341 9.42275C127.558 7.78415 131.996 5.48425 135.214 2.19993L133.786 0.800071Z"
                        fill="#D9D7D8"
                      />
                    </svg>
                    <svg
                      v-if="!offer.interest_constant_duration"
                      class="offer-item__result-arrow-second"
                      width="27"
                      height="43"
                      viewBox="0 0 27 43"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.79289 42.7071C7.18341 43.0976 7.81657 43.0976 8.2071 42.7071L14.5711 36.3432C14.9616 35.9527 14.9616 35.3195 14.5711 34.929C14.1806 34.5385 13.5474 34.5385 13.1569 34.929L7.50001 40.5858L1.8432 34.9289C1.45268 34.5384 0.81951 34.5384 0.428984 34.9289C0.0384564 35.3194 0.0384517 35.9526 0.428973 36.3431L6.79289 42.7071ZM24.652 2.03C24.8873 2.40646 24.9141 2.74166 24.7464 3.19225C24.5517 3.71549 24.1018 4.35479 23.3501 5.19079C22.6073 6.01703 21.6662 6.9339 20.5614 8.042C19.4687 9.13801 18.2454 10.3926 16.9924 11.8474C11.9494 17.7024 6.50011 26.7182 6.5 42L8.5 42C8.50011 27.2818 13.7175 18.7142 18.5078 13.1526C19.7131 11.7532 20.896 10.5391 21.9778 9.45409C23.0474 8.38121 24.049 7.40484 24.8374 6.52796C25.617 5.66083 26.2869 4.78711 26.6208 3.88978C26.9818 2.9198 26.946 1.92687 26.348 0.970001L24.652 2.03Z"
                        fill="#D9D7D8"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3 offer-item__col-fourth">
                <div class="d-flex flex-column offer-item__result">
                  <div
                    class="d-flex flex-column offer-item__result-wrapper justify-content-end"
                  >
                    <label>Koszt kredytu</label>
                    <p class="offer-item__result-details">
                      <span>{{
                        $filters.money(
                          parseFloat(offer.total_to_pay) -
                            parseFloat(offer.brutto)
                        )
                      }}</span
                      ><span class="ml-4">PLN</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <transition v-on:enter="enter" v-on:leave="leave">
              <div
                v-show="isOpen === offer.uid"
                class="offer-item__row--expanded"
              >
                <div
                  class="row w-100 offer-item__row offer-item__row-interests"
                >
                  <div class="col-6 col-md-3 offer-item__col-second">
                    <div class="d-flex flex-column offer-item__result">
                      <div
                        class="d-flex flex-column offer-item__result-wrapper justify-content-end rrso pt-3 pb-3"
                      >
                        <label class="d-flex align-items-center"
                          >RRSO
                          <span
                            class="question-mark pointerable"
                            data-tippy-content="RRSO to rzeczywista roczna stopa oprocentowania, czyli całkowity koszt kredytu jaki poniesiesz. RRSO wyraża się jako wartość procentowa całkowitej kwoty kredytu w ujęciu rocznym."
                          >
                            <svg
                              width="7"
                              height="11"
                              viewBox="0 0 7 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.57 0.116C4.494 0.116 5.24067 0.377333 5.81 0.899999C6.37933 1.42267 6.664 2.12267 6.664 3C6.664 3.98 6.36067 4.708 5.754 5.184C5.14733 5.65067 4.326 5.884 3.29 5.884L3.248 7.214H2.142L2.086 5.002H2.492C3.416 5.002 4.13933 4.862 4.662 4.582C5.18467 4.302 5.446 3.77467 5.446 3C5.446 2.44 5.278 1.99667 4.942 1.67C4.606 1.34333 4.15333 1.18 3.584 1.18C3.00533 1.18 2.548 1.33867 2.212 1.656C1.88533 1.964 1.722 2.38867 1.722 2.93H0.518C0.518 2.37 0.644 1.88 0.896 1.46C1.148 1.03067 1.50267 0.699333 1.96 0.465999C2.42667 0.232666 2.96333 0.116 3.57 0.116ZM2.688 10.084C2.44533 10.084 2.24 10 2.072 9.832C1.904 9.664 1.82 9.45867 1.82 9.216C1.82 8.97333 1.904 8.768 2.072 8.6C2.24 8.432 2.44533 8.348 2.688 8.348C2.92133 8.348 3.11733 8.432 3.276 8.6C3.444 8.768 3.528 8.97333 3.528 9.216C3.528 9.45867 3.444 9.664 3.276 9.832C3.11733 10 2.92133 10.084 2.688 10.084Z"
                                fill="#332930"
                                fill-opacity="0.7"
                              />
                            </svg>
                          </span>
                        </label>
                        <p class="offer-item__result-details">
                          <span>{{ $filters.money(offer.rrso) }}</span
                          ><span class="ml-4">%</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-12 col-md-6 col-xl-5 offset-xl-1 offer-item__col-first offer-item__col-interests"
                  >
                    <small
                      v-if="
                        parseInt(interest_type) === 2 &&
                        offer.interest_constant_duration
                      "
                      class="offer-item__interests-info-stable d-flex justify-content-center"
                    >
                      Po okresie {{ offer.interest_constant_duration }} miesięcy
                      marża + WIBOR = Oprocentowanie
                    </small>
                    <div
                      class="offer-item__interests d-flex justify-content-between pt-3 pb-3"
                    >
                      <div
                        class="d-flex flex-column offer-item__result-wrapper w-100 offer-item__center"
                      >
                        <div
                          class="d-flex flex-column offer-item__result-details justify-content-end margin"
                        >
                          <label class="d-flex align-items-center"
                            >MARŻA
                            <span
                              class="question-mark pointerable"
                              data-tippy-content="Marża jest składową oprocentowania kredytu i jest zyskiem banku za udzielenie kredytu."
                            >
                              <svg
                                width="7"
                                height="11"
                                viewBox="0 0 7 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.57 0.116C4.494 0.116 5.24067 0.377333 5.81 0.899999C6.37933 1.42267 6.664 2.12267 6.664 3C6.664 3.98 6.36067 4.708 5.754 5.184C5.14733 5.65067 4.326 5.884 3.29 5.884L3.248 7.214H2.142L2.086 5.002H2.492C3.416 5.002 4.13933 4.862 4.662 4.582C5.18467 4.302 5.446 3.77467 5.446 3C5.446 2.44 5.278 1.99667 4.942 1.67C4.606 1.34333 4.15333 1.18 3.584 1.18C3.00533 1.18 2.548 1.33867 2.212 1.656C1.88533 1.964 1.722 2.38867 1.722 2.93H0.518C0.518 2.37 0.644 1.88 0.896 1.46C1.148 1.03067 1.50267 0.699333 1.96 0.465999C2.42667 0.232666 2.96333 0.116 3.57 0.116ZM2.688 10.084C2.44533 10.084 2.24 10 2.072 9.832C1.904 9.664 1.82 9.45867 1.82 9.216C1.82 8.97333 1.904 8.768 2.072 8.6C2.24 8.432 2.44533 8.348 2.688 8.348C2.92133 8.348 3.11733 8.432 3.276 8.6C3.444 8.768 3.528 8.97333 3.528 9.216C3.528 9.45867 3.444 9.664 3.276 9.832C3.11733 10 2.92133 10.084 2.688 10.084Z"
                                  fill="#332930"
                                  fill-opacity="0.7"
                                />
                              </svg>
                            </span>
                          </label>
                          <p class="offer-item__result-details">
                            <span>{{ $filters.money(offer.margin) }}</span
                            ><span class="ml-4">%</span>
                          </p>
                        </div>
                      </div>
                      <span
                        class="d-flex align-items-center offer-item__interests-plus mobile-hide"
                        >+</span
                      >
                      <div
                        class="d-flex flex-column offer-item__result-wrapper w-100 offer-item__center"
                      >
                        <div
                          class="d-flex flex-column offer-item__result-details justify-content-end wibor"
                        >
                          <label class="d-flex align-items-center"
                            >WIBOR
                            <span
                              class="question-mark pointerable"
                              data-tippy-content="WIBOR to referencyjna wysokość oprocentowania pożyczek udzielanych między bankami. Zazwyczaj jest ustalana przez banki na okres 3 lub 6 miesięcy."
                            >
                              <svg
                                width="7"
                                height="11"
                                viewBox="0 0 7 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.57 0.116C4.494 0.116 5.24067 0.377333 5.81 0.899999C6.37933 1.42267 6.664 2.12267 6.664 3C6.664 3.98 6.36067 4.708 5.754 5.184C5.14733 5.65067 4.326 5.884 3.29 5.884L3.248 7.214H2.142L2.086 5.002H2.492C3.416 5.002 4.13933 4.862 4.662 4.582C5.18467 4.302 5.446 3.77467 5.446 3C5.446 2.44 5.278 1.99667 4.942 1.67C4.606 1.34333 4.15333 1.18 3.584 1.18C3.00533 1.18 2.548 1.33867 2.212 1.656C1.88533 1.964 1.722 2.38867 1.722 2.93H0.518C0.518 2.37 0.644 1.88 0.896 1.46C1.148 1.03067 1.50267 0.699333 1.96 0.465999C2.42667 0.232666 2.96333 0.116 3.57 0.116ZM2.688 10.084C2.44533 10.084 2.24 10 2.072 9.832C1.904 9.664 1.82 9.45867 1.82 9.216C1.82 8.97333 1.904 8.768 2.072 8.6C2.24 8.432 2.44533 8.348 2.688 8.348C2.92133 8.348 3.11733 8.432 3.276 8.6C3.444 8.768 3.528 8.97333 3.528 9.216C3.528 9.45867 3.444 9.664 3.276 9.832C3.11733 10 2.92133 10.084 2.688 10.084Z"
                                  fill="#332930"
                                  fill-opacity="0.7"
                                />
                              </svg>
                            </span>
                          </label>
                          <p class="offer-item__result-details">
                            <span>{{ $filters.money(offer.rate_value) }}</span
                            ><span class="ml-4">%</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <small
                      v-if="!offer.interest_constant_duration"
                      class="offer-item__interests-info d-flex"
                    >
                      Marża + WIBOR = Oprocentowanie
                    </small>
                  </div>
                  <div class="col-6 col-md-3 offer-item__col-second">
                    <div
                      class="d-flex flex-column offer-item__result-wrapper pt-3 pb-3"
                    >
                      <label class="d-flex align-items-center"
                        >Kwota do spłaty
                        <span
                          class="question-mark pointerable"
                          data-tippy-content="Kwota do spłaty – to łączne koszty, które poniesiesz w związku z umową o kredyt hipoteczny. W jej skład wchodzą głównie odsetki, opłaty, prowizje, podatki i marże oraz koszty usług dodatkowych."
                        >
                          <svg
                            width="7"
                            height="11"
                            viewBox="0 0 7 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.57 0.116C4.494 0.116 5.24067 0.377333 5.81 0.899999C6.37933 1.42267 6.664 2.12267 6.664 3C6.664 3.98 6.36067 4.708 5.754 5.184C5.14733 5.65067 4.326 5.884 3.29 5.884L3.248 7.214H2.142L2.086 5.002H2.492C3.416 5.002 4.13933 4.862 4.662 4.582C5.18467 4.302 5.446 3.77467 5.446 3C5.446 2.44 5.278 1.99667 4.942 1.67C4.606 1.34333 4.15333 1.18 3.584 1.18C3.00533 1.18 2.548 1.33867 2.212 1.656C1.88533 1.964 1.722 2.38867 1.722 2.93H0.518C0.518 2.37 0.644 1.88 0.896 1.46C1.148 1.03067 1.50267 0.699333 1.96 0.465999C2.42667 0.232666 2.96333 0.116 3.57 0.116ZM2.688 10.084C2.44533 10.084 2.24 10 2.072 9.832C1.904 9.664 1.82 9.45867 1.82 9.216C1.82 8.97333 1.904 8.768 2.072 8.6C2.24 8.432 2.44533 8.348 2.688 8.348C2.92133 8.348 3.11733 8.432 3.276 8.6C3.444 8.768 3.528 8.97333 3.528 9.216C3.528 9.45867 3.444 9.664 3.276 9.832C3.11733 10 2.92133 10.084 2.688 10.084Z"
                              fill="#332930"
                              fill-opacity="0.7"
                            />
                          </svg>
                        </span>
                      </label>
                      <p class="offer-item__result-details">
                        <span>{{ $filters.money(offer.total_to_pay) }}</span
                        ><span class="ml-4">PLN</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  v-show="
                    isOpen === offer.uid &&
                    offer.expires_at &&
                    offer.expires_at.length > 0
                  "
                  class="offer-item__bank-validity desktop-hide"
                >
                  <p>
                    Oferta ważna do
                    {{ moment(offer.expires_at).format("DD MM YYYY") }}!
                  </p>
                </div>
              </div>
            </transition>
            <transition v-on:enter="enter" v-on:leave="leave">
              <div
                v-show="isOpen === offer.uid"
                class="row w-100 offer-item__row mt-8"
              >
                <div class="offer-item__details">
                  <h3>Wymagane produkty dodatkowe</h3>
                  <ul class="offer-item__details-list">
                    <template
                      v-for="(e, k) in offer.additions"
                      :key="'offer_' + key + '_addition_' + k"
                    >
                      <li v-if="parseInt(k) !== 11">{{ e[0].name }}</li>
                    </template>
                  </ul>
                  <h3>Koszty początkowe</h3>
                  <ul
                    class="offer-item__details-list offer-item__details-list--costs"
                  >
                    <li
                      v-if="
                        typeof offer.initial_costs_values[1] !== 'undefined' &&
                        parseFloat(offer.initial_costs_values[1].value) > 0
                      "
                    >
                      <p>Prowizja za udzielenie kredytu</p>
                      <span
                        >{{
                          $filters.money(offer.initial_costs_values[1].value)
                        }}
                        PLN</span
                      >
                    </li>
                    <li
                      v-if="
                        typeof offer.initial_costs_values[2] !== 'undefined'
                      "
                    >
                      <p>Wycena nieruchomości</p>
                      <span
                        >{{
                          $filters.money(offer.initial_costs_values[2].value)
                        }}
                        PLN</span
                      >
                    </li>
                    <li
                      v-if="
                        typeof offer.initial_costs_values[3] !== 'undefined' &&
                        parseFloat(offer.initial_costs_values[3].value) > 0
                      "
                    >
                      <p>Ubezpieczenie na życie</p>
                      <span
                        >{{
                          $filters.money(offer.initial_costs_values[3].value)
                        }}
                        PLN</span
                      >
                    </li>
                    <li
                      v-if="
                        typeof offer.initial_costs_values[4] !== 'undefined' &&
                        parseFloat(offer.initial_costs_values[4].value) > 0
                      "
                    >
                      <p>Ubezpieczenie od utraty pracy</p>
                      <span
                        >{{
                          $filters.money(offer.initial_costs_values[4].value)
                        }}
                        PLN</span
                      >
                    </li>
                  </ul>
                </div>
                <p class="offer-item__promo">
                  Banki potrzebują zdecydowanie więcej informacji, żeby
                  przygotować ofertę. Skontaktuj się z Ekspertem ANG
                  Odpowiedzialne Finanse, żeby uzyskać dokładniejsze wyliczenia.
                </p>
              </div>
            </transition>
          </div>
          <div class="col-12 col-lg-2">
            <div class="offer-item__right-col">
              <div class="d-flex offer-item__right-col-content">
                <button
                  class="badge badge--red mb-4 mobile-hide"
                  data-bs-toggle="modal"
                  data-bs-target="#leadForm"
                  @click="scrollUp"
                >
                  Umów rozmowę
                </button>
                <button
                  @click="collapse(offer.uid)"
                  class="offer-item__collapse-link"
                  :class="{ collapsed: isOpen !== offer.uid }"
                >
                  <span v-show="isOpen !== offer.uid">Rozwiń Szczegóły</span>
                  <span v-show="isOpen === offer.uid">Zwiń Szczegóły</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center" style="min-height: 50vh">
      <h2 class="header__title">Brak ofert</h2>
      <p>
        Bardzo nam przykro, ale nie znaleźliśmy ofert spełniających Twoje
        kryteria.
      </p>
      <div class="d-flex justify-content-center mt-8">
        <a
          href="#"
          class="button button--rounded"
          data-bs-toggle="modal"
          data-bs-target="#leadForm"
          @click="scrollUp"
          >umów bezpłatną konsultację z ekspertem</a
        >
      </div>
    </div>
  </div>
  <div>
    <div class="col-12">
      <p class="intro__legal mb-4">
        Prezentowane informacje mają charakter wyłącznie poglądowy i nie
        stanowią oferty w rozumieniu kodeksu cywilnego, ani przyrzeczenia
        udzielenia finansowania. Ich celem jest zwrócenie uwagi konsumenta na
        parametry poszczególnych ofert kredytowych, które mają charakter
        szacunkowy. Zawierają informacje dodatkowe, o których mowa w art.12
        ustawy o kredycie hipotecznym oraz o nadzorze nad pośrednikami kredytu
        hipotecznego i agentami (dalej: ustawa o kredycie hipotecznym).
        Ostateczne warunki kredytowe, jak również decyzja kredytowa, uzależnione
        są od oceny zdolności kredytowej klienta dokonywanej przez bank, a
        wszelkie informacje wymagane przepisami ustawy o kredycie hipotecznym
        przekazywane są oddzielnie zgodnie z procedurami poszczególnych
        kredytodawców.
      </p>
      <p class="intro__legal mb-4">
        ANG Odpowiedzialne Finanse S.A z siedzibą w Warszawie jest powiązanym
        pośrednikiem kredytu hipotecznego w rozumieniu ustawy z dnia 23 marca
        2017 roku o kredycie hipotecznym oraz o nadzorze nad pośrednikami
        kredytu hipotecznego i agentami. ANG Odpowiedzialne Finanse S.A. jest
        umocowana do wykonywania czynności faktycznych związanych z
        pośrednictwem kredytowym w zakresie wynikającym z zawartych z
        kredytodawcami umów agencyjnych. Aktualna lista kredytodawców: Alior
        Bank S.A., Bank Polskiej Spółdzielczości S.A., BNP Paribas Bank Polska
        SA, BOŚ Bank S.A., Citibank Handlowy, ING Bank Śląski S.A., mBank, Bank
        Millennium S.A., Pekao S.A., PKO BP S.A., Santander Bank Polska SA, SBR
        Bank. Spółdzielczy Bank Rozwoju, jak również jest dostępna u agentów.
      </p>
      <p class="intro__legal mb-4">
        <a
          href="https://drive.google.com/file/d/1FFn49MG6qY5PJNAlNds8zm0dRq-B28a5/view"
          target="_blank"
          >Regulamin usługi "Porównywarka kredytów hipotecznych"</a
        >
      </p>
    </div>
    <div class="col-12">
      <div class="intro__img-wrapper desktop-hide">
        <img
          @load="handleLoad"
          @error="handleLoad"
          class="intro__promo"
          src="../../../public/assets/img/main.png"
          alt="Plakat promujący różne banki"
        />
        <img
          @load="handleLoad"
          @error="handleLoad"
          class="intro__circles"
          src="../../../public/assets/img/shapes/intro-circles.svg"
          alt="kropki ozdobne tło"
        />
        <img
          @load="handleLoad"
          @error="handleLoad"
          class="intro__blue-shape"
          src="../../../public/assets/img/shapes/intro-blue-shape.svg"
          alt="ozdobny kształt"
        />
        <img
          @load="handleLoad"
          @error="handleLoad"
          class="intro__yellow-shape"
          src="../../../public/assets/img/shapes/intro-yellow-shape.svg"
          alt="ozdobny kształt"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Velocity from "velocity-animate";
import spinner from "@/components/partials/spinner";
import tippy from "tippy.js";
import moment from "moment";

export default {
  name: "searchResults",
  data() {
    return {
      isOpen: false,
      banks: {
        alior: "big-alior.png",
        boś: "big-bos.png",
        "Bank Polskiej Spółdzielczości": "big-bps.png",
        "Velo Bank": "big-velo.png",
        citi: "big-citibank.png",
        bnp: "big-bnp.png",
        ing: "big-ing.png",
        mbank: "big-mbank.png",
        mille: "big-millennium.png",
        pekao: "big-pekao.png",
        pko: "big-pko.png",
        santander: "big-santander.png",
        sbr: "big-sbr.png",
      },
    };
  },
  components: {
    spinner,
  },
  props: ["loading", "offers", "interest_type", "fetched"],
  computed: {
    fixedDataOffers: function () {
      return this.offers.map((item) => {
        return {
          ...item,
          expires_at: item.expires_at ? item.expires_at.substring(0, 10) : "",
        };
      });
    },
  },
  watch: {
    loading() {
      this.isOpen = false;
    },
    isOpen() {
      tippy("[data-tippy-content]", { allowHTML: true });
    },
  },
  methods: {
    scrollUp() {
      window.top.postMessage("scrollup", "*");
    },
    moment(...args) {
      return moment(args).locale("pl");
    },
    getLogo(institutution) {
      let keys = Object.keys(this.banks);
      for (let i = 0; i < keys.length; i++) {
        if (institutution.toLowerCase().indexOf(keys[i].toLowerCase()) >= 0) {
          return this.banks[keys[i]];
        }
      }
    },
    collapse(uid) {
      if (uid === this.isOpen) {
        this.isOpen = false;
      } else {
        this.isOpen = uid;
      }
    },
    enter: function (el, done) {
      Velocity(
        el,
        "slideDown",
        {
          duration: 400,
          easing: "easeInBack",
        },
        { complete: done }
      );
      this.$emit("emitHeight");
    },
    leave: function (el, done) {
      Velocity(
        el,
        "slideUp",
        {
          duration: 400,
          easing: "easeInBack",
        },
        { complete: done }
      );
      this.$emit("emitHeight");
    },
  },
};
</script>
